<template>
    <div>
        <div v-if="showGroup" class="groupDetail">
            <div class="groupDetail-nav van-hairline--bottom">
                <img src="@/assets/qunliao.svg">
                <div>
                    <div style="font-size:16;font-weight: bold;">{{details.group_name}}</div>
                    <div v-if="class_detail.class_name" style="font-size:12px">
                        <van-icon class="mr5" class-prefix="icon" size="10" name="InterlinkageOutline" />
                        <span>{{class_detail.class_name}}</span>
                    </div>
                </div>
            </div>
            <van-row class="groupDetail-col">
                <van-col span="6">
                    <h1 class="groupDetail-num">{{details.member_count}}</h1>
                    <div class="groupDetail-num">全部成员</div>
                </van-col>
                <van-col span="6">
                    <h1 class="groupDetail-num">{{details.total_revenue_count}}</h1>
                    <div class="groupDetail-num">累计课消</div>
                </van-col>
                <van-col span="6">
                    <h1 class="groupDetail-num">{{details.month_revenue_count}}</h1>
                    <div class="groupDetail-num">本月课消</div>
                </van-col>
                <van-col span="6">
                    <h1 class="groupDetail-num">{{details.month_extended_count}}</h1>
                    <div class="groupDetail-num">本月签单</div>
                </van-col>
            </van-row>
            <div class="groupDetail-bg"></div>
            <div class="groupDetail-time">
                <div>
                    <span style="margin-right:10px;color:#999">建群时间:</span>
                    <span v-if="details.create_time">{{dayjs(details.create_time * 1000).format('YYYY-MM-DD HH:mm')}}</span>
                </div>
                <div class="groupDetail-sex"> 
                    <span style="color:#999">成员性别: </span>
                    <div>
                        <span class="groupDetail-sex-item">
                            <img src="@/assets/woman.png">
                            <span>{{sex_count.famle}}人</span>
                        </span>
                        <span class="groupDetail-sex-item">
                            <img src="@/assets/man.png">
                            <span>{{sex_count.male}}人</span>
                        </span>
                        <span class="groupDetail-sex-item">
                            <img src="@/assets/unknow.png">
                            <span>{{sex_count.other}}人</span>
                        </span>
                    </div>
                </div>
            </div>
            <div class="groupDetail-bg"></div>
            <van-row class="groupDetail-tab">
                <van-col span="24">
                    <van-tabs v-model="search.type" @change="handleChange">
                        <van-tab :name="1" :title="`在册学员 (${external_contact_count})`"></van-tab>
                        <van-tab :name="0" :title="`内部员工 (${employee_contact_count})`"></van-tab>
                        <van-tab :name="2" :title="`开课记录 (${course_class_count})`"></van-tab>
                    </van-tabs>
                </van-col>
            </van-row>
            <van-list
                v-model="loading"
                :finished="finished"
                :finished-text="listFinishedText"
                @load="onLoad"
                >
                <div v-if="search.type !=2">
                    <van-search v-model="search.name" @search="onSearch" @input="onSearch" placeholder="请输入名称" />
                </div>
                <!-- <div v-if="search.type !=2" @click="toDetail(item)" class="groupDetail-item" v-for="(item,index) in list" :key="index"> -->
                <div v-if="search.type !=2" @click="toBuy(item)" class="groupDetail-item" v-for="(item,index) in list" :key="index">
                    <div class="groupDetail-item-avatar">
                        <img v-if="item.avatar" :src="item.avatar">
                        <img v-else src="@/assets/avatar-default.svg">
                    </div>
                    <div class="groupDetail-item-content">
                        <div>
                            <span v-if="search.type === 1">{{item.remark}} ({{item.name}}) </span>
                            <span v-if="search.type === 0">{{item.name}} </span>
                            <span> {{item.corp_name?`@${item.corp_name}`:''}}</span>
                        </div>
                        <div class="groupDetail-item-tag">
                            <img v-if="item.gender == 2" style="width: 12px;margin-right:5px" src="@/assets/woman.png">
                            <img v-if="item.gender == 1" style="width: 12px;margin-right:5px" src="@/assets/man.png">
                            <van-tag v-if="item.is_staff && item.department" color="#f8f8f8" text-color="#999">{{item.department}}</van-tag>
                            <span class="groupDetail-item-time">
                                <van-icon v-if="search.type === 1 && !item.id" name="info-o" />
                                <van-icon style="margin-right:5px" v-else name="clock-o" />
                                <span v-if="search.type != 2" margin-right:5px>{{item.join_time}}</span>
                                <span v-if="search.type === 1 && item.id">
                                    <span class="groupDetail-item-available-time"> 课时:{{item.available_times}}</span>
                                    <span> 到课:{{item.student_had_course_count}}</span>
                                </span>
                                <span v-if="search.type === 1 && !item.id" class="groupDetail-item-notice">
                                    未添加任意企业员工, 无法获取学员信息
                                </span>
                            </span>
                        </div>
                        <div>
                            <van-tag style="margin-right:5px" v-for="(it,ind) in item.tags" :key="ind" round color="#f4f4f4" text-color="#999">{{it.tag_name}}</van-tag>
                        </div>
                    </div>
                    <!-- <div class='groupDetail-item-more'>
                        <van-icon v-if="search.type === 1" @click.stop="toBuy(item)" size='20' name="ellipsis" />
                    </div> -->
                </div>
                <div v-if="search.type ==2" class="qywx_schedule_item">
                    <van-cell-group>
                        <van-cell @click="toCheckin(item)" v-for="(item,index) of list" :key="index" :label="`${item.course_name}`" :value="item.teacher">
                            <template #title>
                                <span>{{`${dayjs(item.class_date).format('MM/DD【dddd】')} ${item.class_time}`}}</span>
                            </template>
                            <template #label>
                                <van-tag plain mark type="success" style="margin-right:10px">排课{{`${item.reserve_count}`}}人</van-tag>
                                <van-tag plain mark type="success" style="margin-right:10px">签到{{`${item.signin_count}`}}人</van-tag>
                                <van-tag plain mark type="success">请假{{`${item.cancel_count}`}}人</van-tag>
                                <!-- <span class="custom-title">{{`${item.course_name}`}}</span> -->
                            </template>
                        </van-cell>
                    </van-cell-group>
                </div>
            </van-list>
        </div>
        <div v-if="showUser" class="userDetail">
            <div v-show='!searchLoading' class="header mb20">
                <div class="header-block">
                    <div class="header-block-item header-block-item--first">
                    <div class="header-block-media">
                        <van-image class="header-block-avatar" :src="userInfo.avatarUrl" alt="" />
                    </div>
                    <div class="header-block-title">
                        <span>{{ userInfo.nickName }}</span>
                    </div>
                    </div>
                    <div v-for="(item,index) in studentList" :key="index" class="header-block-items" @click="onUserSwitch(item.id)">
                    <div class="header-block-medias">
                        <van-image class="header-block-avatars" :src="item.avatarUrl" alt="" />
                        <div class="header-block-bg"></div>
                    </div>
                    <div class="header-block-titles">
                        <span>{{ item.nickName }}</span>
                    </div>
                    </div>
                </div>
            </div>
            <div v-show='!searchLoading' class="main user-main">
                <div class="group balance-group">
                    <van-cell :value="currentCampus" is-link @click="onShowStudio" />
                    <div class="group-inner">
                    <div v-for="(item,index) in premiumList" :key="index" class="group-item" @click="toPath(item)">
                        <div class="balance-group-title">{{ item.value | dataPlaceholder }}</div>
                        <div class="balance-group-txt">{{ item.title }}</div>
                    </div>
                    </div>
                </div>
            </div>
            <div v-show='!searchLoading' class="userDetail-addB">
                <div class="userDetail-add" @click="showPopup=true">
                    <div style="display: flex;align-items: center;">
                        <span style="margin-right:10px">Ta添加的: </span>
                        <span class="userDetail-add-img">
                            <img v-show="index <3" v-for="(item,index) in  external_contact_follow" :key="index" :style="{marginLeft:index==0?'0px':'-5px'}" :src="item.avatar">
                        </span>
                        <span v-if="external_contact_follow.length>3">...</span>
                        <span style="margin-left:15px">共{{external_contact_follow.length}}个员工</span>
                    </div>
                    <div class="userDetail-add-group">
                        共{{external_contact_group.length}}个群聊
                        <van-icon name="arrow" />
                    </div>
                </div>
            </div>
        </div>
        <van-share-sheet v-model="showShare" @select="onSelect" :options="shareOptions"></van-share-sheet>
        <van-share-sheet v-model="showPopupGroup" @select="onSelectGroup" :options="shareOptionsGroup"></van-share-sheet>
        <loadings :loading='searchLoading' />
        <van-popup v-model="showPopup" round position="bottom" :style="{ height: '70%' }">
            <div class="userDetail-popup">
                <div class="van-hairline--bottom">
                    <div class="userDetail-popup-title">{{nickName}}添加的员工（ {{external_contact_follow.length}} )</div>
                    <div v-for="(item,index) in external_contact_follow" :key="index" class="userDetail-popup-item">
                        <img :src="item.avatar">
                        <div>
                            <div style="margin-bottom:6px">{{item.name}}</div>
                            <div class="userDetail-popup-group-owner" style="display:flex;align-items:center"><van-icon style="margin-right:5px" name="clock-o" /> {{item.createtime}}</div>
                        </div>
                    </div>
                    <div v-if="external_contact_follow.length == 0" class="userDetail-popup-empty">暂无添加员工企业微信~</div>
                </div>
                <div style="padding-top:10px">
                    <div class="userDetail-popup-title">{{nickName}}加入的群聊（ {{external_contact_group.length}} )</div>
                    <div v-for="(item,index) in external_contact_group" :key="index" class="userDetail-popup-item">
                        <img src="@/assets/qunliao.svg">
                        <div>
                            <div>{{item.group_name}} ({{item.group_member_count}})</div>
                            <div class="userDetail-popup-group-owner">群主: {{item.group_owner}}</div>
                            <div v-if="item.is_in"><van-tag color="#edf5ff" text-color="#00cca2">我已在群聊中</van-tag></div>
                        </div>
                        <van-icon class-prefix="icon" v-clipboard:copy="item.group_name" v-clipboard:success="onCopy" v-clipboard:error="onError" class="userDetail-popup-copy" name="CopyOutline" />
                    </div>
                    <div v-if="external_contact_group.length == 0" class="userDetail-popup-empty">暂无群聊~</div>
                </div>
            </div>
        </van-popup>
        <div 
            v-show="showUser && !showBinging"
            class="float-ball"
            ref="ball"
            @touchstart.stop='handleTouchstart'
            @touchmove.stop='handleTouchmove'
            @touchend.stop='handleTouchend'
            @click.stop="showShare=true"
            :style="{top:`${moveTop}px`,left:`${moveLeft}px`}"
            >
            <van-icon color="#00cca2" name="more-o" />
        </div>
        <div 
            v-show="showGroup"
            class="float-ball"
            ref="ball"
            @touchstart.stop='handleTouchstart'
            @touchmove.stop='handleTouchmove'
            @touchend.stop='handleTouchend'
            @click.stop="showPopupGroup=true"
            :style="{top:`${moveTop}px`,left:`${moveLeft}px`}"
            >
            <van-icon color="#00cca2" name="more-o" />
        </div>
        <div v-if="showBinging">
            <van-empty>
                <div slot="description">
                    <span>暂无关联学员,</span>
                    <span @click="toBinging" class="userDetail-binging">去绑定</span>
                </div>
            </van-empty>
        </div>

        <div v-if="showUser" class="userDetail-step">
        
            <van-list
            v-model="loading"
            :finished="finished"
            :finished-text="listFinishedText"
            @load="onLoad"
            style="margin-bottom:20px"
            >
            <div v-for="(item,index) in list" :key="index" class="schedule-comment-list">
                <div class="schedule-comment-list-nav">
                    <avatar :src="item.creater.teacher_avartar" color="#fff" :size="28"></avatar>
                    <div class="schedule-comment-list-name">
                        <div>{{item.creater.teacher_name}}</div>
                        <div class="schedule-comment-list-time">{{item.message_created_at}}</div>
                    </div>
                </div>
                <div class="schedule-comment-list-content">
                    {{item.message_content}}
                </div>
                <div class="schedule-comment-list-img">
                    <van-image
                    v-for="(it,j) in item.message_images"
                    :key="j"
                    @click="toPreview(item.message_images,j)"
                    width="65"
                    height="65"
                    fit="contain"
                    :src="it"
                    />
                </div>
                <!-- <div @click="toLike(item)" class="schedule-comment-list-like">
                    <van-icon name="good-job-o" :color="item.commnet_is_liked?'#00cca2':''" />
                    <span :style="{marginLeft:'10px',color:item.commnet_is_liked?'#00cca2':''}">{{item.commnet_like_count}}</span>
                </div> -->
            </div>
            <van-empty v-if="list.length == 0 && !loading" description="暂无学员档案~" />
            </van-list>
            <!-- <van-steps direction="vertical" :active="-1">
                <van-list
                    v-model="loading"
                    :finished="finished"
                    :finished-text="listFinishedText"
                    @load="onLoadLog"
                    >
                    <van-step v-for="item in list" :key="item.id">
                        <div class="userDetail-step-user">
                            <div class="userDetail-step-avatar">
                                <avatar :username="item.employee_name" :src="item.employee_avatar" color="#fff" :size="24"></avatar>
                                <span style="margin:0 5px;font-size:12px">{{item.employee_name}}</span>
                            </div>
                            <div style="font-size:12px">
                                {{item.created_at}}
                            </div>
                        </div>
                        <div>{{item.log}}</div>
                        <div class="userDetail-step-img">
                            <img v-for="(it,ind) in item.log_images" :key="ind" @click="toImagePreview(item.log_images,ind)" :src="it.image" alt="">
                        </div>
                    </van-step>
                </van-list>
            </van-steps> -->
        </div>

        <van-popup v-model="showState" round position="bottom">
            <van-form @submit="onSubmitState">
                <van-field name="radio" label="跟进状态">
                    <template #input>
                        <van-radio-group :value="task_step" direction="horizontal">
                            <van-radio @click="handleRadioChange(item)" style="margin-bottom:5px" v-for="item in dealStatus" :key='item.value' :name="item.value">{{item.label}}</van-radio>
                        </van-radio-group>
                    </template>
                </van-field>
                <van-button type="primary" size="large">发布</van-button>
            </van-form>
        </van-popup>
        <van-popup v-model="showLog" round position="bottom">
            <van-form @submit="onSubmitLog">
                <van-field
                    v-model="follow_log"
                    rows="4"
                    autosize
                    label="跟进内容"
                    type="textarea"
                    maxlength="500"
                    placeholder="请输入跟进内容"
                    show-word-limit
                />
                <van-button type="primary" size="large">发布</van-button>
            </van-form>
        </van-popup>
        <van-popup v-model="showPromise" round position="bottom">
            <van-datetime-picker
                :value='modaldata.promise_date_time?dayjs(modaldata.promise_date_time).$d:dayjs().$d'
                type="datetime"
                title="选择年月日"
                :min-date="minDate"
                :max-date="maxDate"
                @confirm='handlePromiseTime'
                />
        </van-popup>
        <van-popup v-model="showClass" round position="bottom">
            <van-picker
                show-toolbar
                :columns="classList"
                value-key="label"
                @cancel="showClass = false"
                @confirm="onConfirmClass"
            />
        </van-popup>

        <van-popup v-model="messageShow" position="bottom" :style="{ height: '100%' }" >
            <div class="schedule-comment-popup">
                <div class="schedule-comment-popup-nav">
                    <div @click="cancel">
                        <van-button size="small" type="default">取消</van-button>
                    </div>
                    <div>
                        <van-button :loading="isConfirm" @click="confirm" size="small" type="primary">确认</van-button>
                    </div>
                </div>
                <div class="schedule-comment-popup-form">
                    <van-form @submit="confirm" :colon='true'>
                        <van-field
                            v-model="message"
                            rows="2"
                            autosize
                            label="档案内容"
                            name="content"
                            type="textarea"
                            maxlength="1000"
                            placeholder="请输入学员档案内容"
                            show-word-limit
                            class="survey-van-textarea-boeder"
                            :required='true'
                            :rules="[{ required: true, message: '请输入' }]"
                        />
                        <van-field 
                            name="image"
                            label="档案图片"
                            :required='false'
                            >
                            <template #input>
                                <van-uploader :after-read="e=>afterRead(e)" :before-delete='(e,b)=>beforeDelete(e,b)' multiple :max-count="9" v-model="imgList" />
                            </template>
                        </van-field>
                    </van-form>
                </div>
            </div>
        </van-popup>

    </div>
</template>

<script>
    import upload from '@/utils/upload'
    import dayjs from 'dayjs'
    import 'dayjs/locale/zh-cn'
    import Avatar from 'vue-avatar'
    import { weWorkchatConfig } from '@/common/wx'
    import Loadings from '@/components/loading'
    import { wxWorkOpenUserProfile } from '@/common/wx'
    import { Col, Row, Tab, Tabs, DropdownMenu, DropdownItem, List, Tag, Search, ShareSheet, Popup, Empty, Form, Field, RadioGroup, Radio, Toast, DatetimePicker, Step, Steps,ImagePreview, Picker, Uploader} from 'vant'
    export default {
        name:'groupDetail',
        data() {
            return {
                isConfirm:false,
                loading:false,
                finished:false,
                showShare:false,
                showShareGroup:false,
                showPopup:false,
                showPopupGroup:false,
                searchLoading:false,
                showClass:false,
                messageShow:false,
                message:'',
                imgList:[],
                list:[],
                listFinishedText: '没有更多了',
                pageSize: 10,
                pageCurrent: 1,
                details:{},
                class_detail:{},
                sex_count:{},
                external_chatid:'wrTssaDAAAgFJbcF1wcdaprF2Far8NxQ',
                addProfiles:this.$store.state.app.qywx_profile,
                search:{
                    name:'',
                    type:1
                },
                shareOptions: [
                    [
                        // { name: '状态变更', icon: require('../../assets/follow.png'), id:2 },
                        // { name: '跟进内容', icon: require('../../assets/follow_txt.png'), id:3 },
                        // { name: '诺访时间', icon: require('../../assets/promise_date.png'), id:4 },
                        { name: '学员档案', icon: require('../../assets/follow_txt.png'), id:6 },
                        { name: '课程购买', icon: require('../../assets/buy_course.png'), id:5 },
                    ]
                ],
                shareOptionsGroup: [
                    [
                        { name: '关联班级', icon: require('../../assets/connect.png'), id:2 }
                        // { name: '课后反馈', icon: require('../../assets/follow_txt.png'), id:3 },
                        // { name: '作业布置', icon: require('../../assets/homework.png'), id:4 },
                        // { name: '课后点名', icon: require('../../assets/promise_date.png'), id:5 },
                    ]
                ],
                external_contact_follow:[],
                external_contact_group:[],
                userInfo:{},
                external_userid:'',
                showGroup:false,
                showUser:false,
                showBinging:false,
                studentList:[],
                premiumList:[],
                studioList:[],
                classList:[],
                moveTop:415,
                moveLeft:317,
                task_id:'',
                student_id:'',
                external_contact_count:0,
                employee_contact_count:0,
                course_class_count:0,
                nickName:'',
                modaldata:{},
                follow_log:'',
                showState:false,
                showLog:false,
                showPromise:false,
                task_step:-1,
                task_step_text:'',
                minDate: new Date(2000, 1, 1),
                maxDate: new Date(9999, 12, 30),
                dealStatus: [
                    { label: '未跟进', value: 0 , color:'#1ab394'},
                    { label: '跟进中', value: 1 , color:'#23c6c8'},
                    { label: '已预约', value: 2 , color:'#10aeff'},
                    { label: '已到访', value: 3 , color:'#f8ac59'},
                    { label: '已成单', value: 4 , color:'#f76260'},
                    { label: '暂搁', value: 5 , color:'#ababab'},
                    { label: '无效', value: 10 , color:'#ababab'},
                    { label: '未接通', value: 6 , color:'#ababab'},
                    { label: '长期跟进', value: 15 , color:'#ababab'},
                ],
            }
        },
        components: {
            [Col.name]:Col,
            [Row.name]:Row,
            [Tab.name]:Tab,
            [Tabs.name]:Tabs,
            [List.name]:List,
            [Tag.name]:Tag,
            [Step.name]:Step,
            [Steps.name]:Steps,
            [Picker.name]:Picker,
            [Form.name]:Form,
            [Field.name]:Field,
            [Empty.name]:Empty,
            [Popup.name]:Popup,
            [Radio.name]:Radio,
            [Avatar.name]: Avatar,
            [RadioGroup.name]:RadioGroup,
            [Search.name]:Search,
            [ShareSheet.name]:ShareSheet,
            loadings:Loadings,
            [DropdownMenu.name]:DropdownMenu,
            [DropdownItem.name]:DropdownItem,
            [Uploader.name]: Uploader,
            [DatetimePicker.name]:DatetimePicker,
        },
        computed: {
            currentCampus() {
                if (this.studioList.length) {
                    return this.studioList[0].name
                } else {
                    return '--'
                }
            }
        },
        created () {
            this.searchLoading = true
            let that = this
            dayjs.locale('zh-cn')
            if(!this.$route.query.external_chatid && !this.$route.query.external_userid){
                weWorkchatConfig()
                .then(res=>{
                    that.getCurExternalChat()
                })
                .catch(err=>{
                    console.log(err)
                })
            }else{
                if(this.$route.query.external_chatid){
                    let chatId = this.$route.query.external_chatid
                    that.showGroup = true
                    that.external_chatid  = chatId  //返回当前客户群的群聊ID
                    localStorage.setItem('profile_showGroup',that.showGroup)
                    localStorage.removeItem('profile_showUser')
                    localStorage.setItem('profile_external_chatid',that.external_chatid)
                    that.getDetail()
                    that.getList()
                    that.getClass()
                }
                if(this.$route.query.external_userid){
                    let userId = this.$route.query.external_userid
                    that.showUser = true
                    that.external_userid  = userId //返回当前外部联系人userId
                    localStorage.setItem('profile_showUser',that.showUser)
                    localStorage.removeItem('profile_showGroup')
                    localStorage.setItem('profile_external_userid',that.external_userid)
                    this.getUserInfo(this.$route.query.student_id)
                }
            }
            // this.showUser = true;
            // this.getUserInfo()
            // this.getDetail()
            // this.getList()
            // this.getClass()
        },
        mounted(){
            if(localStorage.getItem('profile_binging') == 1){
                if(localStorage.getItem('profile_showUser')){
                    this.showUser = localStorage.getItem('profile_showUser')
                    this.external_userid = localStorage.getItem('profile_external_userid')
                    this.loading = true
                    this.finished = false
                    this.list = []
                    this.pageCurrent = 1
                    this.getUserInfo(this.$route.query.student_id)
                }
            }
        },
        methods: {
            dayjs,
            getUserInfo(id){
                // this.external_chatid='wrTssaDAAAgFJbcF1wcdaprF2Far8NxQ';
                // this.$api.qywx_external_contact_profile({external_userid:'wmTssaDAAAXPKmDdMagqOqs7YBV8hXzQ',student_id:id})
                // console.log({external_userid:this.external_userid,student_id:id});
                this.$api.qywx_external_contact_profile({external_userid:this.external_userid,student_id:id})
                .then(res=>{
                    localStorage.removeItem('profile_binging')
                    if(res.data.student){
                        this.userInfo = this.initUserInfo(res.data.student,1)
                        this.external_contact_group = res.data.external_contact_group
                        this.external_contact_follow = res.data.external_contact_follow
                        if (res.data.student_switch.length) {
                            this.studentList = res.data.student_switch.map(item => this.initUserInfo(item))
                        }
                        this.premiumList = res.data.premium.filter(item => item.show === 1)
                        this.studioList = res.data.student_belong_studio.map(item => ({ name: item.studio_name }))
                        this.task_id = res.data.task_id
                        this.modaldata = res.data.task_detail
                        this.modaldata.task_id = res.data.task_id
                        this.getFollowLog()
                        this.searchLoading = false
                        this.showBinging = false
                    }else{
                        this.searchLoading = false
                        this.showBinging = true
                        this.showGroup = false
                        this.showUser = false
                    }
                })
                .catch(err=>{
                    this.showGroup = false
                    this.showUser = false
                    this.searchLoading = false
                })
            },
            groupChat(item){
                let chat_id = item.chat_id;
                this.$router.push({path:'/qywx/crm/chatRecord',query:{session_type:'external_group',session_ext_id:chat_id}})
            },
            getFollowLog(){
                this.$api.qywx_external_message({student_id:this.student_id})
                .then(res=>{
                    this.loading = false
                    this.searchLoading = false
                    this.list = Number(res._meta.currentPage) === 1 ? res.items : this.list.concat(res.items)
                    this.finished = this.list.length >= res._meta.totalCount
                    this.listFinishedText = this.list.length ? '没有更多了~' : ''
                    this.pageCurrent += 1
                })
            },
            initUserInfo(item,num) {
                if(num){
                    this.student_id = item.student_id
                    this.nickName = item.student_name
                }
                const userInfo = {
                    id: item.student_id,
                    nickName: item.student_name || `${item.student_name.student_id}$`,
                    avatarUrl: item.student_avatar || `${require('@/assets/avatar_default.png')}`,
                    sex: item.student_gender ? String(item.student_gender) : '1',
                    birthday: item.student_birthday || '',
                    height: item.student_height || '',
                    weight: item.student_weight || '',
                    health: item.student_health_status || '',
                    address: item.student_address || ''
                }
                return userInfo
            },
            afterRead(file,name) {
                this.$forceUpdate()
                let that = this
                this.isConfirm = true
                if(!file.length){
                    upload({ file: file.file })
                    .then(res => {
                        file.path  = res.fileName
                    })
                    .catch(err => {
                        that.$toast({ message: '图片上传失败~', duration: 1500 })
                    })
                }else{
                    file.forEach(it=>{
                        that.isConfirm = true
                        upload({ file: it.file })
                            .then(res => {
                                it.path  = res.fileName
                            })
                            .catch(err => {
                                that.$toast({ message: '图片上传失败~', duration: 1500 })
                            })
                    })
                }
                setTimeout(()=>{
                    that.isConfirm = false
                },1500)
            },
            beforeDelete(file,detail,name){
                this.imgList.splice(detail.index,1)
                this.$forceUpdate()
                return true
            },
            cancel(){
                this.message = ''
                this.messageShow = false
            },
            confirm(){
                if(!this.message){
                    this.$toast('请输入评论内容～')
                    return false
                }
                let obj = {
                    message_content:this.message,
                    student_id:this.student_id,
                    message_images:[]
                }
                if(this.imgList.length>0){
                    obj.message_images = this.imgList.map((item)=>{return item.path})
                }
                this.loading = true
                this.isConfirm = true
                this.$api.qywx_external_add_message(obj)
                .then(res=>{
                    this.finished = false
                    this.list = []
                    this.imgList = []
                    this.pageCurrent = 1
                    this.$toast('操作成功～')
                    this.getFollowLog()
                    this.message = ''
                    this.messageShow = false
                })
            },
            getDetail() {
                this.$api.qywx_external_group_profile({external_chatid:this.external_chatid})
                // this.$api.qywx_external_group_profile({external_chatid:'wrTssaDAAAgFJbcF1wcdaprF2Far8NxQ'})
                .then(res=>{
                    this.details = res.data
                    this.sex_count = res.data.sex_count
                    this.class_detail = res.data.class_detail
                    this.course_class_count = res.data.course_class_count
                    this.employee_contact_count = res.data.employee_contact_count
                    this.external_contact_count = res.data.external_contact_count
                    this.searchLoading = false
                })
            },
            getList(){
                this.$api.qywx_external_group_members({ 'per-page': this.pageSize, page: this.pageCurrent, external_chatid:this.external_chatid,search:this.search})
                // this.$api.qywx_external_group_members({ 'per-page': this.pageSize, page: this.pageCurrent, external_chatid:'wrTssaDAAAgFJbcF1wcdaprF2Far8NxQ',search:this.search})
                .then(res=>{
                    this.loading = false
                    this.searchLoading = false
                    localStorage.removeItem('profile_binging')
                    this.list = Number(res._meta.currentPage) === 1 ? res.items : this.list.concat(res.items)
                    this.finished = this.list.length >= res._meta.totalCount
                    if(res.items.length==0){
                        this.finished =true;
                        this.listFinishedText = '没有更多了~'
                    }
                    this.listFinishedText = this.list.length ? '没有更多了~' : '暂无人员~'
                    this.pageCurrent += 1
                })
            },
            getClass(){
                this.$api.qywx_external_group_can_related_class({external_chatid:this.external_chatid})
                // this.$api.qywx_external_group_can_related_class({external_chatid:'wrTssaDAAAgFJbcF1wcdaprF2Far8NxQ'})
                .then(res=>{
                    this.classList = res.items.map(item => ({ label: item.class_name, value: item.class_id }))
                })
            },
            getClassesSchedule(){
                this.$api.classes_schedule({class_id:this.details.class_id,'per-page': this.pageSize, page: this.pageCurrent})
                .then(res=>{
                    this.loading = false
                    this.searchLoading = false
                    this.list = Number(res._meta.currentPage) === 1 ? res.items : this.list.concat(res.items)
                    this.finished = this.list.length >= res._meta.totalCount
                    this.listFinishedText = this.list.length ? '没有更多了~' : '暂无记录~'
                    this.pageCurrent += 1
                })
            },
            onLoad(){
                if(this.pageCurrent !=1){
                    if(this.search.type == 2){
                        this.getClassesSchedule()
                    }else{
                        this.getList()
                    }
                }
            },
            onLoadLog(){
                if(this.pageCurrent !=1){
                    this.getFollowLog()
                }
            },
            getCurExternalChat(){
                let that = this
                wx.invoke('getCurExternalChat', {
                    }, function(res){
                    if(res.err_msg == "getCurExternalChat:ok"){
                        that.showGroup = true
                        that.external_chatid  = res.chatId  //返回当前客户群的群聊ID
                        localStorage.setItem('profile_showGroup',that.showGroup)
                        localStorage.removeItem('profile_showUser')
                        localStorage.setItem('profile_external_chatid',that.external_chatid)
                        that.getDetail()
                        that.getList()
                        that.getClass()
                    }else {
                        //错误处理
                    }
                })
                wx.invoke('getCurExternalContact', {
                    }, function(res){
                    if(res.err_msg == "getCurExternalContact:ok"){
                        that.showUser = true
                        that.external_userid  = res.userId //返回当前外部联系人userId
                        localStorage.setItem('profile_showUser',that.showUser)
                        localStorage.removeItem('profile_showGroup')
                        localStorage.setItem('profile_external_userid',that.external_userid)
                        that.getUserInfo()
                    }else {
                        //错误处理
                    }
                })
            },
            toDetail(item){
                let obj = {
                    type:item.is_staff?1:2,
                    userid:item.ext_id
                }
                wxWorkOpenUserProfile(obj)
                .catch(err=>{
                    console.log(err)
                })
            },
            toPath(item){
                if(item.type == "student_book_count"){
                    this.$router.push({path:'/teacher/booking/index',query:{student_id:this.student_id}})
                }
            },
            toCheckin(item){
                this.$router.push({path:'/teacher/checkin',query:{course_class_id:item.course_class_id}})
            },
            toBuy(item){
                this.nickName = item.remark
                this.external_contact_follow = item.external_contact_follow
                this.external_contact_group = item.external_contact_group
                this.showPopup = true
            },
            onSearch(){
                this.list = []
                this.pageCurrent = 1
                this.finished = false
                this.getList()
            },
            onSelect(option){
                if(option.id === 2){
                    this.showState = true
                    this.task_step = this.modaldata.task_step
                }
                if(option.id === 3){
                    this.showLog = true
                }
                if(option.id === 4){
                    this.showPromise = true
                }
                if(option.id === 5){
                    this.$router.push({path:'/teacher/invited/teacherApply',query:{task_id:this.task_id}})
                }

                if(option.id === 6){
                    this.messageShow=true;
                    // this.$toast.success('学员档案!')
                }
            },
            onSelectGroup(option){
                console.log(option)
                if(option.id === 2){
                    this.showClass = true
                }
            },
            onUserSwitch(id) {
                this.loading = true
                this.finished = false
                this.list = []
                this.pageCurrent = 1
                this.getUserInfo(id)
            },
            onShowStudio(){},
            handleChange(){
                this.list = []
                this.pageCurrent = 1
                this.finished = false
                this.loading = true
                if(this.search.type == 2){
                    this.getClassesSchedule()
                }else{
                    this.getList()
                }
            },
            handleTouchstart(e){
                this.moveTop = e.changedTouches[0].clientY - 20
                this.moveLeft = e.changedTouches[0].clientX - 20
            },
            handleTouchmove(e){
                e.preventDefault()
                this.moveTop = e.changedTouches[0].clientY - 20
                this.moveLeft = e.changedTouches[0].clientX - 20
            },
            handleTouchend(e){
                this.moveTop = e.changedTouches[0].clientY - 20 
                this.moveLeft = e.changedTouches[0].clientX - 20
            },
            toBinging(){
                this.$router.push({path:'/qywx/crm/binging',query:{userid:this.external_userid}})
            },
            onCopy(){
                this.$toast.success('已复制到剪切板!')
            },
            onError(){
                this.$toast.error('操作失败!')
            },
            handleRadioChange(item){
                this.task_step = item.value
                this.task_step_text = item.label
            },
            onSubmitState(){
                this.$api.qywx_external_task_update({task_id:this.modaldata.task_id,task_step:this.task_step})
                .then(res=>{
                    Toast.success('操作成功')
                    this.showState = false
                    this.modaldata.task_step = this.task_step
                    this.modaldata.task_step_text = this.task_step_text
                })
                .catch(err=>{
                    Toast.error('操作失败')
                })
            },
            toPreview(item,index){
                wx.previewImage({
                    current: item[index], // 第一张显示的图片链接
                    urls: item // 需要预加载的图片http链接列表，预加载后，可以滑动浏览这些图片
                });
                // WeixinJSBridge.invoke("imagePreview", {
                //     "urls":item,
                //     "current": item[index]
                // })
            },
            onSubmitLog(){
                this.$api.qywx_external_follow({task_id:this.modaldata.task_id,log:this.follow_log})
                .then(res=>{
                    Toast.success('操作成功')
                    this.follow_log = ''
                    this.showLog = false
                    this.loading = true
                    this.finished = false
                    this.list = []
                    this.pageCurrent = 1
                    this.getFollowLog()
                    if(!this.modaldata.task_step){
                        this.modaldata.task_step_text = '跟进中'
                        this.modaldata.task_step = 1
                    }
                })
                .catch(err=>{
                    Toast.error('操作失败')
                })
            },
            handlePromiseTime(e){
                this.$api.qywx_external_task_update({task_id:this.modaldata.task_id,promise_date_time:dayjs(e).format('YYYY-MM-DD HH:mm')})
                .then(res=>{
                    Toast.success('操作成功')
                    this.showPromise = false
                    this.modaldata.promise_date_time = dayjs(e).format('YYYY-MM-DD HH:mm')
                })
                .catch(err=>{
                    Toast.error('操作失败')
                })
            },
            toImagePreview(arr,index){
                let img = []
                arr.forEach(item=>{
                    img.push(item.image)
                })
                ImagePreview(img)
            },
            onConfirmClass(val,index){
                this.$api.qywx_external_group_related_class({external_chatid:this.external_chatid,class_id:val.value})
                // this.$api.qywx_external_group_related_class({external_chatid:'wrTssaDAAAgFJbcF1wcdaprF2Far8NxQ',class_id:val.value})
                .then(res=>{
                    this.showClass = false
                    this.showPopupGroup = false
                    this.$toast('操作成功~')
                    this.searchLoading = true
                    this.getDetail()
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    .style-box() {
        box-sizing: border-box;
        border-radius: 8px;
        overflow: hidden;
        background: #fff;
    }
    .groupDetail{
        min-height: 100vh;
        background: #fff;
        &-bg{
            height: 8px;
            background: #f8f8f8;
        }
        &-nav{
            display: flex;
            align-items: center;
            padding: 10px 0 10px 10px;
            img{
                margin-right: 10px;
                border-radius:2px;
            }
        }
        &-col{
            padding: 5px 0;
        }
        &-num{
            text-align: center;
            padding: 5px 0;
        }
        &-time{
            padding: 10px 0 10px 10px;
        }
        &-sex{
            display: flex;
            align-items: center;
            margin-top: 8px;
            img{
                width: 16px;
                margin-right: 5px;
            }
            div{
                display: flex;
                align-items: center;
                margin-left: 10px;
            }
            &-item{
               display: flex;
               align-items: center; 
               margin-right: 20px;
            }

        }
        &-tab{
            height: 44px;
            border-bottom: 1px solid #ebedf0;
        }
        &-sort{
            height: 44px;
            position: relative;
            display: flex;
            align-items: center;
            &-icon{
                position: absolute;
                right: 10px;
                cursor: pointer;
            }
        }
        &-item{
            display: flex;
            padding: 10px 0 10px 10px;
            position: relative;
            &-avatar{
                margin-right: 10px;
                img{
                    width: 46px;
                    height: 46px;
                    border-radius: 2px;
                }
            }
            &-tag{
                display: flex;
                align-items: center;
                margin-top: 5px;
            }
            &-time{
                display: flex;
                align-items: center;
                font-size: 12px;
                color: #999;
            }
            &-available-time{
                display: inline-block;
                width: 90px;
                margin-right: 5px;
            }
            &-more{
                position: absolute;
                right: 10px;
                padding-bottom: 20px;
                width: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            &-notice{
                margin-left: 5px;
            }
        }
    }
    .userDetail{
        &-nav{
            padding: 10px 0 10px 10px;
            &-box{
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            &-avatar{
                width: 60px;
                height: 60px;
                border-radius: 50%;
                margin-right: 10px;
                margin-bottom: 5px;
            }
            &-name{
                width: 50px;
                height: 22px;
                background: rgba(0,0,0,.2);
                color: #fff;
                line-height: 22px;
                border-radius: 11px;
                padding: 0 5px;
                text-align: center;
                font-size: 12px;
            }
        }
        &-info{
            padding: 10px 0 10px 10px;
            font-size: 12px;
        }
        &-addB{
            padding: 0 15px 15px 15px;
        }
        &-add{
            position: relative;
            display: flex;
            align-items: center;
            font-size: 12px;
            background: #fff;
            border-radius: 8px;
            padding: 10px;
            img{
                width: 24px;
                height: 24px;
                border-radius: 50%;
                border: 1px solid #00cca2;
            }
            &-group{
                position: absolute;
                right: 10px;
                display: flex;
                align-items: center;
            }

        }
        &-popup{
            padding: 10px;
            &-item{
                display: flex;
                padding: 10px 0;
                position: relative;
                img{
                    width: 46px;
                    height: 46px;
                    border-radius: 2px;
                    margin-right: 8px;
                }
            }
            &-empty{
                text-align: center;
                padding: 10px 0;
                font-size: 12px;
                color: #999;
            }
            &-group-owner{
                font-size: 12px;
                color: #999;
            }
            &-copy{
                position: absolute;
                right: 10px;
                color: #999;
            }
        }
        &-binging{
            color: #00cca2;
        }
    }
    .header {
        box-sizing: border-box;
        width: 100%;
        height: 227px;
        color: #fff;
        padding: 0 15px;
        // background-color: #fe4140;
        padding-top: 20px;
        background: @linear-gradient-color;
        background-image: url('~@/assets/user_bg.png');
        background-size: 100% 100%;
        &-media {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 10px;
        }
        &-title {
        font-size: 18px;
        }
        &-setting {
        position: absolute;
        top: 30px;
        right: 30px;
        font-size: 24px;
        }
    }
    .header-block{
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        overflow-x: auto;
        position: relative;
        &-item{
        position: relative;
        margin-right: 10px;
        flex-shrink: 0;
        }
        &-items{
        position: relative;
        margin-right: 10px;
        flex-shrink: 0;
        }
        &-media {
        width: 60px;
        height: 60px;
        overflow: hidden;
        border-radius: 50%;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        }
        &-avatar{
        width: 58px;
        height: 58px;
        overflow: hidden;
        border-radius: 50%;
        }
        &-medias {
        width: 40px;
        height: 40px;
        overflow: hidden;
        border-radius: 50%;
        position: relative;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        }
        &-avatars{
        width: 38px;
        height: 38px;
        overflow: hidden;
        border-radius: 50%;
        }
        &-bg{
        position: absolute;
        top: 1px;
        left: 1px;
        width: 38px;
        height: 38px;
        border-radius: 50%;
        background: rgba(0,0,0,.5);
        }
        &-title {
        text-align: center;
        font-size: 12px;
        background: rgba(0,0,0,.3);
        border-radius: 10px;
        margin-top: 3px;
        height: 21px;
        span{
            display: inline-block;
            width: 50px;
            text-align: center;
            line-height: 1.8;
            color: #fff;
            overflow:hidden;
            text-overflow:ellipsis;
            white-space:nowrap;
            word-wrap:normal;
        }
        }
        &-titles {
        background: rgba(0,0,0,.3);
        border-radius: 10px;
        margin-top: 3px;
        text-align: center;
        height: 18px;
        span{
            display: inline-block;
            width: 30px;
            text-align: center;
            font-size: 10px;
            line-height: 1.8;
            color: #fff;
            overflow:hidden;
            text-overflow:ellipsis;
            white-space:nowrap;
            word-wrap:normal;
        }
        }
        &-change{
        display: flex;
        align-items: center;
        position: absolute;
        right: 0;
        bottom: 2px;
        }
    }
    .main {
        margin-top: -140px;
    }
    .group {
        .style-box();
        margin: 0 15px 15px 15px;
        &-inner{
        padding:10px 0;
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        }
        &-item{
        flex: 1;
        text-align: center;
        }
    }
    .balance-group {
        .style-box();
        .van-row {
        text-align: center;
        }
        .van-col {
        text-align: center;
        }
        &-title {
        font-size: 14px;
        font-weight: bold;
        color: #000;
        margin-bottom: 5px;
        }
        &-txt {
        font-size: 12px;
        color: #999;
        }
    }
</style>

<style lang="less">
    .groupDetail{
        .van-dropdown-menu__bar{
            box-shadow:none;
            font-size: 12px;
            background-color: inherit;
        }
    }
    .float-ball{
        position: fixed;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #fff;
        box-shadow: 0 0 2px #999;
        z-index: 10;
        .van-icon{
            font-size: 0.8rem;
        }
    }
    .userDetail-step{
        height: calc(100vh - 300px);
        overflow-y: auto;
        padding:0px;
        margin-top:-20px;
        border-radius: 8px;
        .van-steps--vertical{
            // padding: 0 0 0 145px;
            border-radius: 8px;
            overflow: hidden;
        }
        .van-list__loading{
            // margin-left: -150px;
        }
        .van-list__finished-text{
            // margin-left: -150px;
            margin-top: 5px;
        }
        &-time{
            // position: absolute;
            // left: -135px;
            // top: 10px;
        }
        &-img{
            img{
                max-width: 46px;
                max-height: 46px;
                margin-right: 5px;
                margin-top: 5px;
                margin-bottom: 5px;
            }
        }
        &-user{
            display: flex;
            align-items: center;
            margin-bottom: 5px;
        }
        &-avatar{
            display: flex;
            align-items: center;
        }

    }
    .qywx_schedule_item{
        .van-cell__title{
            flex: 2;
        }
    }
    .custom-title {
        margin-left: 20px;
        vertical-align: middle;
    }
</style>
<style lang="less">
    .schedule-comment-list{
        width: 90%;
        background: #fff;
        margin: 0 auto;
        margin-top: 15px;
        border-radius: 10px;
        padding: 10px;
        box-shadow: 0 0 2px 3px hsla(0,0%,86.3%,.1);
        position: relative;
        &-nav{
            display: flex;
            align-items: center;
        }
        &-name{
            margin-left:15px;
        }
        &-time{
            font-size: 12px;
            color: #999;
        }
        &-content{
            margin-top:10px;
            word-break: break-all;
        }
        &-img{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            .van-image{
                margin: 10px;
            }
        }
        &-like{
            position: absolute;
            right: 10px;
            top: 10px;
            display: flex;
            align-items: center;
        }
    }
    .schedule-comment-popup{
        &-nav{
            display: flex;
            justify-content: space-between;
            padding: 10px;
        }
    }
    .schedule-comment-popup-form{
        .van-cell{
            display: block;
        }
        .van-field__label{
            width: 100%;
        }
        .survey-van-field-boeder{
            .van-field__body{
                border: 1px solid #d9d9d9;
                padding: 5px;
                border-radius: 2px;
            }
        }
        .survey-van-textarea-boeder{
            .van-cell__value{
                border: 1px solid #d9d9d9;
                padding: 5px;
                border-radius: 2px;
            }
        }
    }
</style>